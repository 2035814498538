import Guages from "../components/Guages/Guages";
import Header from "../components/Header/Header";
import LocationName from "../components/LocationName/LocationName";
import Graphs from "../components/Graphs/Graphs";
import "./App.css";
import Footer from "../components/Footer/Footer";
import DataTable from "../components/DataTable/DataTable";
import About from "../components/About/About";

function App() {
  const locationInfo = {
    locationNameText: "Lymington Yacht Haven",
    locationSensorInfo: "Gill Instruments Windsonic (Option 1) wind sensor",
    locationInfo: "Western Solent",
    sensorImage: "https://r-p-r.co.uk/newimages/windsonic_500.png",
    sensorImageAlt: "Gill Instruments Windsonic (Option 1)",
  };
  return (
    <div className="container">
      <div className="app">
        <Header />
        <LocationName locationInfoData={locationInfo} />
        <Guages />
        <Graphs />

        <DataTable />
        <About />
      </div>
      <Footer />
    </div>
  );
}

export default App;
