import React from "react";

function About() {
  return (
    <div>
      <p>About</p>
    </div>
  );
}

export default About;
