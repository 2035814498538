import React from "react";
import "./LocationName.css";

function LocationName({ locationInfoData }) {
  return (
    <div className="locationContainer">
      <div>
        <h2 className="locationName">{locationInfoData.locationNameText}</h2>
        <p className="locationInfo">
          <b>Data:</b> {locationInfoData.locationSensorInfo}
        </p>
        <p className="locationInfo">
          <b>Location:</b> {locationInfoData.locationInfo}
        </p>
      </div>
      <img
        className="locationSensorImage"
        src={locationInfoData.sensorImage}
        alt={locationInfoData.sensorImageAlt}
      ></img>
    </div>
  );
}

export default LocationName;
