import React from "react";
import "./Header.css";
import Navigation from "../Navitgation/Navigation";

function Header() {
  return (
    <div className="HeaderContainer">
      <a href="https://weatherfile.com/">
        <img
          src="https://weatherfile.com/images/rprwf9.png"
          alt="WeatherFile Logo"
        />
      </a>
      <Navigation />
    </div>
  );
}

export default Header;
