import React from "react";

function Graphs() {
  return (
    <div>
      <p>Graphs</p>
    </div>
  );
}

export default Graphs;
