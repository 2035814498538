import React from "react";
import "./Navigation.css";

function Navigation() {
  return (
    <div
      style={{
        backgroundColor: "#000099",
        display: "flex",
        alignItems: "center",
      }}
    >
      <a href="https://weatherfile.com/" className="NavigationTitle">
        Home
      </a>
      <a href="https://weatherfile.com/settings" className="NavigationTitle">
        Settings
      </a>
      <a href="https://weatherfile.com/locations" className="NavigationTitle">
        Locations
      </a>
      <a
        href="https://r-p-r.co.uk/weatherfile/internet-weather-system.php"
        className="NavigationTitle"
      >
        Products
      </a>
      <a href="https://r-p-r.co.uk/beaufort.php" className="NavigationTitle">
        Beaufort Scale
      </a>
      <a
        href="https://weatherfile.com/account/login"
        className="NavigationTitle"
      >
        Log In
      </a>
    </div>
  );
}

export default Navigation;
